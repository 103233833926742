/**
 * @require ./explorer.js
 */
(function (global) {
    var News = {};
    var newsType = { Issue: 1, Recorditem: 2 };
    var typeFilter, types;
    var offset, issues, recorditems;

    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerNotes = $('#explorer-notes');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $resetFilters = $('#explorer-news-reset-filters');
    var $typeFilter = $('#explorer-news-type-filter');
    var $tableWrapper = $explorerNews.find('.tblContentWrapper');
    var $tableBody = $tableWrapper.find('.table > tbody');
    var activeFilters = {
        TypeFilter: false
    };

    var cachedElements;

    function show(match) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init();
        updateTable();
    }

    function init() {
        offset = null;
        issues = {};
        recorditems = {};
        cachedElements = {};

        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        initTab();
        initLanguage();
        initTypes();
        initFilters();
        unbindEvents();
        bindEvents();
        resize();

        $tableBody.empty();
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.removeClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerNotes.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function initLanguage() {
        $('#explorer-news-type-filter-lbl').text(i18next.t('explorer.news.typeFilter.label'));

        $resetFilters.attr('title', i18next.t('explorer.toolbar.resetFilters'));
        $typeFilter.find('.reset').text(i18next.t('explorer.news.typeFilter.reset'));
        $typeFilter.find('.close').text(i18next.t('explorer.news.typeFilter.close'));
    }

    function initTypes() {
        types = [];

        types.push({ OID: '0', Title: i18next.t('explorer.news.typeFilter.recorditems') });
        types.push({ OID: '1-2', Title: i18next.t('explorer.news.typeFilter.tasks') });

        if (Client.Licenses && Client.Licenses.Surveys) {
            types.push({ OID: '3-7', Title: i18next.t('explorer.news.typeFilter.forms') });
        } else {
            types.push({ OID: '3', Title: i18next.t('explorer.news.typeFilter.forms') });
        }

        types.push({ OID: '4', Title: i18next.t('explorer.news.typeFilter.notes') });
        types.push({ OID: '5', Title: i18next.t('explorer.news.typeFilter.disturbances') });

        if (Client.Licenses && Client.Licenses.Inspections) {
            types.push({ OID: '6', Title: i18next.t('explorer.news.typeFilter.inspections') });
        }

        if (Client.Licenses && Client.Licenses.Surveys) {
            types.push({ OID: '7', Title: i18next.t('explorer.news.typeFilter.surveys') });
        }

        if (Client.Licenses && Client.Licenses.Investigations) {
            types.push({ OID: '8', Title: i18next.t('explorer.news.typeFilter.investigations') });
        }

        types.push({ OID: '9', Title: i18next.t('explorer.news.typeFilter.disturbancesCompleted') });
    }

    function initFilters() {
        initTypeFilter();
        setFilterState();
    }

    function setFilterState() {
        var isActive;

        for (var filter in activeFilters) {
            isActive = activeFilters[filter];

            if (isActive) {
                break;
            }
        }

        isActive ? $resetFilters.addClass('active') : $resetFilters.removeClass('active');
    }

    function initTypeFilter() {
        var text;
        var titles = (typeFilter || []).map(function (type) {
            return type.Title;
        });

        activeFilters.TypeFilter = titles.length > 0;
        $typeFilter.toggleClass('filter-selected', activeFilters.TypeFilter);

        if (activeFilters.TypeFilter) {
            text = titles.length === types.length ?
                    i18next.t('explorer.news.typeFilter.all') :
                    titles.sort().join(', ');
        } else {
            text = i18next.t('explorer.news.typeFilter.noSelection');
        }

        $typeFilter.find('.btn-title').html(text);
    }

    function reset() {
        offset = null;
        issues = {};
        recorditems = {};

        setFilterState();
        $tableBody.empty();

        updateTable()
            .then(Explorer.UpdateTabCounters)
            .then(Explorer.ResizeTabMenu);
    }

    function unbindEvents() {
        $resetFilters.off('click.resetFilters');
        $typeFilter.off('click.showTypeFilter');

        $tableBody.off('click.changeOrganisationUnit');
        $tableBody.off('click.showIssue');
        $tableBody.off('click.showRecorditem');
        $tableBody.off('click.showPrintPreview');
        $tableBody.off('click.toggleSubscription');
        $tableBody.off('click.showComments');
        $tableBody.off('click.stopPropagation');
        $tableBody.off('mouseenter.showSubscriptions');
        $tableBody.off('mouseleave.hideSubscriptions');
    }

    function bindEvents() {
        $resetFilters.on('click.resetFilters', onResetFilters);
        $typeFilter.on('click.showTypeFilter', onShowTypeFilter);

        $tableBody.on('click.changeOrganisationUnit', '.issue a, .recorditem a', onSelectOrganisationUnit);
        $tableBody.on('click.showIssue', '.issue', onShowIssue);
        $tableBody.on('click.showRecorditem', '.recorditem', onShowRecorditem);
        $tableBody.on('click.showPrintPreview', '.print', onShowPrintPreview);
        $tableBody.on('click.toggleSubscription', '.abos', onToggleSubscription);
        $tableBody.on('click.showComments', '.commentlink', onShowComments);
        $tableBody.on('mouseenter.showSubscriptions', '.abos .details', onShowSubscriptions);
        $tableBody.on('mouseleave.hideSubscriptions', '.abos .details', onHideSubscriptions);
    }

    function onShowPrintPreview(event) {
        var issueOID = $(this).closest('.issue').data('oid');

        PrintPreview.Show({
            Issue: issues[issueOID],
            Type: 'Issue'
        });

        event.stopPropagation();
        return false;
    }

    function onToggleSubscription(event) {
        var $this = $(this);
        var $tr = $this.parents('tr');
        var issueOID = $tr.data('oid');
        var subscribe = !$this.data('isabo');
        var issue;

        $('#gfx-tooltip').addClass('hide');

        if ((issue = issues[issueOID])) {
            Tools.Spinner.show();

            var method = 'issues/{0}/{1}'.format(issue.ID, subscribe ? 'subscribenotifications' : 'unsubscribenotifications');

            Tools.http.post(method, null, function () {
                    issue.Recipients = subscribe ?
                        Tools.addUnique(issue.Recipients, User.OID) :
                        Tools.remove(issue.Recipients, User.OID);

                    $this.replaceWith(renderIssueSubscription(issue));

                    Tools.Spinner.hide();
                }, Tools.Spinner.hide);
        }

        event.stopPropagation();
        return false;
    }

    function onShowSubscriptions(event) {
        var $tooltip = $('#gfx-tooltip');
        var $this = $(this);
        var offset = $this.offset();
        var abos, subscribers;

        abos = ($this.data('abos') || '').split('|');

        subscribers = $.map(abos, function (oid) {
            var subscriber = Users[oid] || Contacts[oid];

            if (subscriber) {
                return subscriber.Fullname;
            }
        });

        subscribers.sort(Tools.SortStringArray);

        if (subscribers.length > 10) {
            subscribers = subscribers.slice(0, 10);
            subscribers.push('+ {0} {1}'.format(abos.length - 10, i18next.t('misc.more')));
        }

        $tooltip.html(subscribers.join('<br />'));
        $tooltip.removeClass('hide');
        $tooltip.attr('arrow', 1);
        $tooltip.css({
            left: offset.left - $tooltip.outerWidth(false) / 2 + 8,
            top: offset.top - $tooltip.outerHeight(false) - 8
        });
    }

    function onHideSubscriptions() {
        $('#gfx-tooltip').addClass('hide');
    }

    function onShowComments(event) {
        var $this = $(this);
        var issueID = $this.parents('tr').data('id');

        IssueViewer.Show(issueID, reset, onCommentsChanged, { Panel: 'comments' });

        event.stopPropagation();
        return false;
    }

    function updateTable() {
        $tableWrapper.off('scroll.infiniteScroll');

        if (!Explorer.GetCurrentTab()) {
            return $.Deferred().reject().promise();
        }

        Tools.Spinner.show();

        var params = createParams();

        return load(params)
            .then(prepareNews, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(appendNews, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(function (news) {
                if (!(news instanceof Array) || !news.length) {
                    Tools.Spinner.hide();
                    return;
                }

                $tableBody.find('img')
                    .off('error')
                    .on('error', Tools.OnImageNotFound);

                if (news.length) {
                    offset = news[news.length - 1].Timestamp;
                }

                if (news.length === 50) {
                    $tableWrapper.on('scroll.infiniteScroll', onScroll);
                }

                news.forEach(function (newsItem) {
                    switch (newsItem.Type) {
                        case newsType.Issue:
                            issues[newsItem.Issue.OID] = newsItem.Issue;
                            break;
                        case newsType.Recorditem:
                            recorditems[newsItem.Recorditem.OID] = newsItem.Recorditem;
                            break;
                    }
                });

                Tools.Spinner.hide();

                return news;
            });
    }

    function resize() {
        var maxWidth = $explorerNews.find('.table-control').width() / 3;

        $explorerNews.find('.table-control .table-button').css('max-width', maxWidth);
    }

    function resetFilters() {
        $resetFilters.removeClass('active');

        activeFilters = {
            TypeFilter: false
        };

        typeFilter = null;
    }

    function onResetFilters() {
        resetFilters();
        initFilters();
        resize();
        reset();
    }

    function onShowTypeFilter() {
        var options = {
            title: i18next.t('explorer.news.typeFilter.title'),
            onApply: onSelectTypeFilter,
            checkedEntities: typeFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(types, options);
    }

    function onSelectTypeFilter(selectedTypes) {
        typeFilter = (selectedTypes || []).map(function (oid) {
            return Tools.getFirst(types, oid, 'OID');
        });

        initTypeFilter();
        resize();
        reset();
    }

    function onScroll() {
        var wrapperHeight = $tableWrapper.innerHeight();
        var listHeight = $tableBody.outerHeight(false);
        var scrollPosition = $tableWrapper.scrollTop();

        if (listHeight - wrapperHeight - scrollPosition <= 300) {
            $tableWrapper.off('scroll.infiniteScroll');

            updateTable();
        }
    }

    function onSelectOrganisationUnit(event) {
        event.stopPropagation();
    }

    function onShowIssue(event) {
        var $this = $(this);
        var issueID = $this.data('id');

        IssueViewer.Show(issueID, reset, onCommentsChanged);

        event.stopPropagation();
        return false;
    }

    function onCommentsChanged(changeInfo) {
        if (!changeInfo) {
            return;
        }

        var issue = issues[changeInfo.IssueOID];

        if (!issue) {
            return;
        }

        issue.Comments = Tools.issue.prepareCommentsForWebApp(changeInfo.Comments);

        var $issue = $tableWrapper.find('tr[data-oid="' + issue.OID + '"]');

        if (!$issue.length) {
            return;
        }

        $issue.find('.commentdetails').remove();

        var commentsMarkup = renderIssueComments(issue);
        $issue.find('.location').after(commentsMarkup);
    }

    function onShowRecorditem(event) {
        var $this = $(this);
        var $parent = $tableBody;
        var left = $parent.scrollLeft();
        var top = $parent.scrollTop();
        var recorditemOID = $this.data('oid');

        event.stopPropagation();

        RecorditemDetails.show({
            OID: recorditemOID,
            $Target: $this,
            $Parent: $parent,
            $Container: $parent,
            IsReadonly: !CurrentEntity.Enabled,
            Offset: {
                X: left,
                Y: top
            }
        });

        $('body').one('click.closeRecorditemDetails', RecorditemDetails.hide);
    }

    function createParams() {
        var params = {
            offset: offset ? Tools.dateTime.toGMTString(offset) : null,
            take: 50,
            locationoid: CurrentEntity.OID,
            withchildlocations: Explorer.GetWithChildLocations(),
            withdeactivatedelements: Explorer.GetWithDeactivatedElements()
        };

        (typeFilter || []).forEach(function (type) {
            var oid = type.OID.replace('-', '|');

            if (oid === '0') {
                params.types = Tools.addUnique(params.types, newsType.Recorditem);
            } else {
                params.types = Tools.addUnique(params.types, newsType.Issue);
                params.issuetypes = Tools.addUnique(params.issuetypes, oid);
            }
        });

        if (params.types) {
            params.types = params.types.join('|');

            if (params.issuetypes) {
                params.issuetypes = params.issuetypes.join('|');
            }
        }

        return params;
    }

    function prepareNews(news) {
        var missingElements = (news || []).filter(function (news) {
            return news.Type === newsType.Recorditem &&
                news.Recorditem &&
                !cachedElements.hasOwnProperty(newsType.ElementOID);
        })
        .map(function (news) {
            return {
                OID: news.Recorditem.ElementOID,
                WithAncestors: true
            };
        });

        var deferred = $.Deferred();

        if (!missingElements.length) {
            return deferred.resolve($.map(news || [], prepareNewsItem)).promise();
        }

        Tools.http.post('elements/', missingElements)
            .then(function (elements) {
                var cache = Tools.element.createHierarchyForDownloadedElements(elements);

                if (!cache) {
                    return;
                }

                for (var oid in cache) {
                    cachedElements[oid] = cache[oid];
                }
            }, deferred.reject)
            .then(function () {
                return deferred.resolve($.map(news || [], prepareNewsItem));
            });

        return deferred.promise();
    }

    function prepareNewsItem(newsItem) {
        newsItem.Timestamp = new Date(newsItem.Timestamp);

        switch (newsItem.Type) {
            case newsType.Issue:
                newsItem.Issue = Tools.issue.prepareIssueForWebApp(newsItem.Issue);
                break;
            case newsType.Recorditem:
                newsItem.Recorditem = prepareRecorditem(newsItem.Recorditem);
                break;
        }

        return newsItem;
    }

    function prepareRecorditem(recorditem) {
        recorditem.CreationTimestamp = new Date(recorditem.CreationTimestamp);
        recorditem.ModificationTimestamp = new Date(recorditem.ModificationTimestamp);

        recorditem.Creator = Users[recorditem.CreatorOID];
        recorditem.Editor = Users[recorditem.EditorOID];
        recorditem.Element = cachedElements[recorditem.ElementOID];
        recorditem.Category = recorditem.CategoryOID ? Properties[recorditem.CategoryOID] : null;
        recorditem.LocationPath = Explorer.CreateOrganisationUnitPath(recorditem.ElementOID, cachedElements);

        return Tools.Escape.Recorditem(recorditem);
    }

    function appendNews(news) {
        var html;

        if (!(news || []).length) {
            if (!offset) {
                html = [
                    '<tr class="empty">',
                        '<td colspan="3">',
                            '<div class="content-panel-row">',
                                '<div class="content-panel empty">',
                                    '<p class="text">{0}</p>'.format(i18next.t('explorer.values.noDataAvailable')),
                                '</div>',
                            '</div>',
                        '</td>',
                    '</tr>'
                ];

                $tableBody.append(html.join(''));
            }

            return news;
        }

        news.sort(function (a, b) {
            return b.Timestamp.getTime() - a.Timestamp.getTime();
        });

        html = $.map(news || [], renderNews);

        $tableBody.append(html.join(''));

        return news;
    }

    function renderNews(newsItem) {
        return newsItem.Type === newsType.Issue ?
                renderIssueNews(newsItem) :
                renderRecorditemNews(newsItem);
    }

    function renderIssueNews(newsItem) {
        var html = [];
        var issue = newsItem.Issue;

        html.push(
            '<tr class="issue" data-oid="{0}" data-id="{1}" data-editor-oid="{2}">'
                .format(issue.OID, issue.ID, issue.EditorOID),
                '<td>',
                    renderIssueId(issue),
                    renderIssueIcon(issue),
                    '<img src="./img/pdf-icon.svg" class="print">',
                '</td>',
                '<td>',
                    renderIssueTitle(issue),
                    renderIssueMailAddresses(issue),
                    renderInvestigationStatus(issue),
                    renderIssueParameterCount(issue),
                    renderIssueLocationPath(issue),
                    renderIssueComments(issue),
                '</td>',
                '<td>',
                    renderIssueEditor(issue),
                    renderIssueModificationTimestamp(issue),
                    renderIssueState(issue),
                '</td>',
            '</tr>'
        );

        return html.join('');
    }

    function renderRecorditemNews(newsItem) {
        var html = [];
        var recorditem = newsItem.Recorditem;

        html.push(
            '<tr class="recorditem" data-oid="{0}" data-editor-oid="{1}">'.format(recorditem.OID, recorditem.EditorOID),
                '<td>',
                    renderRecorditemId(recorditem),
                    '<img src="./img/pencil_write_48.png" class="typeIcon">',
                '</td>',
                '<td>',
                    renderRecorditemTitle(recorditem),
                    renderRecorditemValue(recorditem),
                    renderRecorditemLocationPath(recorditem),
                '</td>',
                '<td>',
                    renderRecorditemEditor(recorditem),
                    renderRecorditemModificationTimestamp(recorditem),
                    renderRecorditemCategory(recorditem),
                '</td>',
            '</tr>'
        );

        return html.join('');
    }

    function renderIssueId(issue) {
        return '<span class="issue-id">{0}{1}.{2}</span>'.format(
            Tools.GetIssueAbbreviation(issue),
            issue.ID,
            issue.Revision);
    }

    function renderIssueIcon(issue) {
        return '<img src="{0}" class="typeIcon">'.format(getIssueIcon(issue.Type));
    }

    function renderIssueTitle(issue) {
        return '<span class="title">{0}</span> {1}'.format(
            issue.Title || i18next.t('misc.untitled'),
            getIssueModificationDescription(issue));
    }

    function renderIssueDescription(issue) {
        return issue.Description ?
                '<p class="description">{0}</p>'.format(issue.Description) :
                '';
    }

    function renderIssueParameterCount(issue) {
        var html = [];

        if (!issue.ParameterCount) {
            return '';
        }

        html.push('<p class="parameter-count">');

        html.push(i18next.t('explorer.news.collectedCheckpointCount', {
            collectedCheckpointCount: issue.CollectedParameterCount || 0,
            checkpointCount: issue.ParameterCount
        }));

        if (issue.RequiredParameterCount) {
            html.push('<br />');
            html.push(i18next.t('explorer.news.collectedRequiredCheckpointCount', {
                collectedCheckpointCount: issue.CollectedRequiredParameterCount || 0,
                checkpointCount: issue.RequiredParameterCount
            }));
        }

        html.push('</p>');

        return html.join('');
    }

    function renderIssueLocationPath(issue) {
        return issue.LocationPath ?
                '<p class="location">{0}</p>'.format(issue.LocationPath) :
                '';
    }

    function renderIssueMailAddresses(issue) {
        var mailTokens = ((issue.AdditionalData || {}).MailToken || '').split('|');
        var mailAddresses;

        if (mailTokens.length <= 1) {
            return '';
        }

        mailAddresses = mailTokens.slice(1).join(', ');

        return '<p class="mail-addresses">{0}</p>'.format(mailAddresses);
    }

    function renderInvestigationStatus(issue) {
        if (!issue.InvestigationStatusInformation) {
            return '';
        }

        var errorText = issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ?
            Tools.issue.getInvestigationErrorText(issue.InvestigationStatusInformation.ErrorCode) :
            null;

        return '<p class="investigation-status{0}" title="{1}">{2}{3}</p>'
            .format(
                issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ? ' with-error' : '',
                i18next.t('misc.investigationStatus.helpText'),
                Tools.issue.getInvestigationStatusText(issue.InvestigationStatusInformation.Status),
                !!errorText ? (' (' + errorText + ')') : ''
            );
    }

    function renderIssueComments(issue) {
        var html = [];

        html.push('<p class="commentdetails">');
        html.push(renderIssueSubscription(issue));
        html.push(' - <span class="commentlink"');

        if ((issue.Comments || []).length) {
            html.push(' data-comments="{0}"'.format(issue.Comments.length));
        }

        html.push('>{0}</span></p>'.format(i18next.t('misc.comment_plural')));

        return html.join('');
    }

    function renderIssueSubscription(issue) {
        var recipients = issue.Recipients;

        if (Tools.contains(recipients, User.OID)) {
            return '<span class="abos" data-isabo="true">{0} <span class="details" data-abos="{1}">({2})</span></span>'
                .format(
                    i18next.t('misc.unsubscribe'),
                    recipients.join('|'),
                    recipients.length);
        } else if ((recipients || []).length) {
            return '<span class="abos">{0} <span class="details" data-abos="{1}">({2})</span></span>'
                .format(
                    i18next.t('misc.subscribe'),
                    recipients.join('|'),
                    recipients.length);
        }

        return '<span class="abos">{0}</span>'.format(i18next.t('misc.subscribe'));
    }

    function renderIssueEditor(issue) {
        var html = [];
        var editor = issue.Editor;

        html.push('<img class="editor-image" src="{0}">'.format(Tools.Users.getProfilePicturePath(editor)));
        html.push('<p class="editor-title">{0}</p>'.format(editor ? editor.Title : i18next.t('misc.unknown')));

        return html.join('');
    }

    function renderIssueModificationTimestamp(issue) {
        return '<p class="modification-timestamp">{0}</p>'.format(
            Tools.dateTime.getDifferenceString(issue.ModificationTimestamp));
    }

    function renderIssueState(issue) {
        return issue.State ?
                '<p class="state"><div class="color" style="background-color: {0}">&nbsp;</div>{1}</p>'
                    .format(issue.State.Color, issue.State.Title) :
                '';
    }

    function renderRecorditemId(recorditem) {
        return '<span class="recorditem-id">{0}.{1}</span>'.format(recorditem.ID, recorditem.Revision);
    }

    function renderRecorditemTitle(recorditem) {
        var title = (recorditem.Element || {}).Title || i18next.t('misc.untitled');

        return i18next.t('explorer.news.checkpointHasBeenCollected', { checkpointTitle: title });
    }

    function renderRecorditemValue(recorditem) {
        var settings = {
            alignLeft: true,
            suppressInfos: true,
            suppressCategory: true
        };

        if (!recorditem || typeof recorditem.Value === 'undefined') {
            return '<p class="recorditem-value">-/-</p>';
        }

        var value = Tools.values.render($.extend(recorditem), settings);

        return '<p class="recorditem-value">' + value + '</p>';
    }

    function renderRecorditemLocationPath(recorditem) {
        return recorditem.LocationPath ?
                '<p class="location">{0}</p>'.format(recorditem.LocationPath) :
                '';
    }

    function renderRecorditemEditor(recorditem) {
        var html = [];
        var editor = recorditem.Editor;

        html.push('<img class="editor-image" src="{0}">'.format(Tools.Users.getProfilePicturePath(editor)));
        html.push('<p class="editor-title">{0}</p>'.format(editor ? editor.Title : i18next.t('misc.unknown')));

        return html.join('');
    }

    function renderRecorditemModificationTimestamp(recorditem) {
        return '<p class="modification-timestamp">{0}</p>'.format(
            Tools.dateTime.getDifferenceString(recorditem.ModificationTimestamp));
    }

    function renderRecorditemCategory(recorditem) {
        return recorditem.Category ?
                '<p class="category"><div class="color" style="background-color: {0}">&nbsp;</div>{1}</p>'
                    .format(recorditem.Category.Color, recorditem.Category.Title) :
                '';
    }

    function getIssueIcon(type) {
        switch (type) {
            case Enums.issueType.Note:
                return './img/notes.svg';
            case Enums.issueType.Disturbance:
                return './img/disturbance_reported.svg';
            case Enums.issueType.DisturbancesCompleted:
                return './img/archive-icon.png';
            case Enums.issueType.Inspection:
                return './img/jobsite.svg';
            case Enums.issueType.Investigation:
            case Enums.issueType.Form:
            case Enums.issueType.Survey:
                return './img/form.svg';
            case Enums.issueType.Resubmission:
                return './img/checklist.svg';
            default:
                return './img/task.svg';
        }
    }

    function getIssueModificationDescription(issue) {
        if (issue.State && issue.State.ChangeDescription) {
            return issue.State.ChangeDescription;
        }

        if (issue.Revision === 1) {
            return i18next.t('explorer.news.hasBeenCreated');
        }

        return issue.IsArchived ?
                i18next.t('explorer.news.hasBeenArchived') :
                i18next.t('explorer.news.hasBeenModified');
    }

    function load(params) {
        return Tools.http.get('reports/news', null, null, params);
    }

    function disposeTab() {
        cachedElements = null;
        $tableBody.empty();
    }

    News.Show = show;
    News.Resize = resize;
    News.ResetFilters = resetFilters;
    News.Dispose = disposeTab;

    return (global.News = News);
})( window.Explorer || (window.Explorer = {}) );